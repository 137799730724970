.content {
    width: 100%;
    height: 320px;
    overflow-x: auto;
    overflow-scrolling: touch;
    text-align: center;
    display: flex;
    flex-direction: row;
}

.wagon {
    margin: auto;
}

.wagon-box {
    display: flex;
    height: 250px;
    border: #2d3748 4px solid;
    margin: 10px 10px 0 10px;
}

.first-class {
    background-color: rgba(255, 227, 135, 0.3);
}

button {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    border-radius: 0;
    appearance: none;
}

.wagon-box svg {
    font-size: 38px;
    height: 40px;
    width: 40px;
    fill: #ccc;
}

.seat span {
    color: #ccc;
}

.available svg {
    fill: #2d3748;
}

.available span {
    color: #2d3748;
}

.active svg {
    fill: #dbba00;
}

.active span {
    color: #dbba00;
}

.seat-row, .compartment-row {
    display: flex;
    flex-direction: column;
}

.wide {
    padding: 0 10px;
}

.table-row .table {
    display: block;
    position: relative;
    background-color: #ccc;
    height: 50px;
    width: 30px;
}

.table-row .empty-table {
    display: block;
    position: relative;
    height: 50px;
    width: 30px;
}

.table:nth-child(2) {
    margin-top: 142px;
}

.wall-row .wall, .wall-compartment .wall, .wall-compartment-top .wall {
    display: block;
    position: relative;
    border: #2d3748 2px solid;
    height: 49px;
}

.wall-compartment .wall, .wall-compartment-top .wall {
    height: 37px;
}

.wall-row .wall:nth-child(3) {
    margin-top: 49px;
}

.wall-compartment .wall:first-child {
    margin-top: 94px;
}

.wall-compartment-top .wall:last-child {
    margin-bottom: 94px;
}

.compartment {
    border-top: #2d3748 2px solid;
}

.compartment-staff {
    width: 148px;
    height: 148px;
    border-bottom: 4px solid #2d3748;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.compartment-lounge {
    width: 200px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    height: 100%;
}

.compartment-row .top-wall-left {
    margin-top: auto;
    margin-right: 15px;
    border-bottom: #2d3748 4px solid;
}

.compartment-row .top-wall-right {
    margin-top: auto;
    margin-left: 15px;
    border-bottom: #2d3748 4px solid;
}

.compartment-row .bottom-wall-left {
    margin-bottom: auto;
    margin-right: 15px;
    border-bottom: #2d3748 4px solid;
}

.compartment-row .bottom-wall-right {
    margin-bottom: auto;
    margin-left: 15px;
    border-bottom: #2d3748 4px solid;
}

.seat {
    display: block;
    position: relative;
    margin: 4px 12px;
}
.seat-spacer {
    margin: 9px 12px;
}

.reverse svg {
    transform: scale(-1,1);
}

.seat-row .seat:nth-child(3) {
    margin-top: auto;
}

.seat-number {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2px;
    font-size: 12px;
    font-weight: bold;
    transform: translate(-50%, -50%);
}

.seat-number-single {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2px;
    margin-left: 2px;
    font-size: 12px;
    font-weight: bold;
    transform: translate(-50%, -50%);
}

.reverse .seat-number-single {
    margin-left: 0;
}

.reverse .seat-number {
    margin-left: -2px;
}

.vestibule {
    position: relative;
    width: 80px;
}

.wc-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: #fff;
    border-top: 4px solid #2d3748;
    border-right: 4px solid #2d3748;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.wc-bottom-right {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: #fff;
    border-top: 4px solid #2d3748;
    border-left: 4px solid #2d3748;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}


.wc-top-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: #fff;
    border-right: 4px solid #2d3748;
    border-bottom: 4px solid #2d3748;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.wc-top-right {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: #fff;
    border-left: 4px solid #2d3748;
    border-bottom: 4px solid #2d3748;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}
